import {
  Row,
  Col,
  Card,
  Table,
  Select,
  Button,
  Modal,
  Tooltip,
  Form,
  Input,
  Tag,
  Space
} from "antd";
import PhoneInput from 'react-phone-input-2'
import { useNavigate } from "react-router";

import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect, useRef } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import useDebounce from "../hooks/useDebounce";
const Search = Input.Search;
const { Option } = Select;
function Photographer() {
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const navigate = useNavigate()
  const [searchedColumn, setSearchedColumn] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [activeFilter, setActiveFilter] = useState(null);
const [chargeFilter, setChargeFilter] = useState(null);
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [deleteVisible ,setDeleteVisible] = useState(false)
  const [selected, setSelected] = useState();
  const debouncedSearchText = useDebounce(searchText, 300);
  const searchInput = useRef(null);
  const { confirm } = Modal;
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const showConfirm = (record,type) => {

    // setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?? </Button>,
        onOk() {
          statusChange(record,type)
        },
        onCancel() {
          console.log('Cancel');
        },
      // });
    }, 500);

  };

  const showDelete = (record,type) => {

    // setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want to delete ?? </Button>,
        onOk() {

          statusChange(record,type)
        },
        onCancel() {
          console.log('Cancel');
        },
      // });
    }, 500);

  };

  const statusChange = (record,type) => {
    console.log(record, 155)
    const payload = {}
    console.log(payload, 164)
    setLoading(true);
    request({
      url: `/admin/photographer/statusChange/${record}/${type}?`,
      method: 'put',
      data: payload,
      onSuccess: (data) => {
        // Reload the window

        setRefresh(prev => !prev);
        setLoading(false)

      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const columns = [
    {
      title: "Name",
      render: (_, { firstName, lastName }) => {
        return (
          <div>
            {firstName + " " + lastName}
          </div>


        );
      },
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNo",
      render: (_, { countryCode, mobileNo }) => {
        return (
          <div>
            {" +" +countryCode + " " + mobileNo}
          </div>


        );
      },
      key: "mobileNo",
    },
    {
      title: "Total Enquiries",
      dataIndex: "enquiryDataCount",
      key: "enquiryDataCount",
    },
    {
      title: "Total Bookings",
      dataIndex: "bookingDataCount",
      key: "bookingDataCount",
    },
    {
      title: "Charges",
      key:"is_free",
      dataIndex:"is_free",
      filters: [
        {
          text: 'Free',
          value: true,
        },
        {
          text: 'Paid',
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_free === value,
      render: (_, { is_free, _id}) => {
        let color = is_free ? 'green' : 'red';
        return (
          <Tag color={color} key={is_free} onClick={(e) => showConfirm(_id, "is_free")} >
            {is_free == true ? "Free" : "Paid"}
          </Tag>
        );

      },
    },
    {
      title: "STATUS",
      key:"is_active",
      dataIndex:"is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (_, { is_active, _id}) => {
        let color = is_active ? 'green' : 'red';
        return (
          <Tag color={color} key={is_active} onClick={(e) => showConfirm(_id,"is_active")} >
            {is_active ? "Active" : "Inactive"}
          </Tag>
        );
      },
    },
    {
      title: "Registartion date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          new Date(created_at).toLocaleDateString()
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>

      <Tooltip placement="top" title={"Edit"}>
          <Button className="tableBtn" onClick={() => {
            setSelected(record)
            setVisible(true)
          }}>
          <i class="fa fa-pencil" aria-hidden="true"></i>
          </Button>
      </Tooltip>

          &nbsp;&nbsp;
      <Tooltip placement="top" title={"View"}>
          <Button className="tableBtn" onClick={() => {
            navigate(`${record._id}/view-photographer`)
          }}>
          <i class="fa fa-eye" aria-hidden="true"></i>
          </Button>
          
      </Tooltip>
      <Tooltip placement="top" title={"Delete"}>

      <Button className="tableBtn" onClick={() => {
            showDelete(record._id, "is_delete")
          }}>
          <i class="fa fa-trash-o" aria-hidden="true"></i>
          </Button>
          </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const activeFilters = filters ? filters.is_active : null
    const chargeFilters = filters ? filters.is_free : null;

    console.log(filters, 2188)
    request({
      url: `/admin/photographer/list?status=${activeFilters ? activeFilters.join(',') : ''}&charges=${chargeFilters ? chargeFilters.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        console.log("defrgtyhrgfed",data);
        setLoading(false)
        // ShowToast(data.message, Severty.SUCCESS)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const handleChange = (pagination, filters) => {
    console.log(pagination, filters);
    fetchData(pagination, filters);
  } 
  return (
    <Main>
      <div className="tabled">
        <Row>
          <Col xs="24" xl={24}>
          <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Photographer Management"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    onPressEnter={onSearch}
                    placeholder=" Search by Name....."
                  />
            
                  <Button onClick={(e) => setVisible(true)}>Add Photographer</Button>
                </>
              }
            >

              <div className="table-responsive">

                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={pagination}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>


          </Col>
        </Row>
      </div>
      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [mobileNumber, setMobileNumber] = useState({
    mobileNo: '',
    country_code: '',
  });


  const [form] = Form.useForm();
  const { request } = useRequest()

  const [loading, setLoading] = useState(false)
  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobileNo: value.slice(data.dialCode.length) });
  }
  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
    setMobileNumber({
      mobileNo: data.mobileNo,
      country_code: data.countryCode
    })
  }, [data])

  const onCreate = (values) => {
    const { email, firstName, lastName , password, companyName,is_free } = values
    const payload = {}
    if(mobileNumber.mobileNo.length!=10){
      return ShowToast("Mobile number is not correct", Severty.ERROR);
    }    // if (!email) return ShowToast('Please enter valid email ', Severty.ERROR)
    setLoading(true)
    payload.email = email.trim()
    payload.firstName = firstName
    payload.lastName = lastName
    payload.companyName = companyName
    payload.password = password
    payload.countryCode = mobileNumber.country_code
    payload.mobileNo =  mobileNumber.mobileNo
    payload.is_free = is_free
    
    request({
      url: `${data ? `/admin/photographer/edit-photographer/${data._id}` : `/admin/photographer/add-photographer`}`,
      method: `${data ? 'PUT' : 'POST'}`,
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        console.log(data , 364444444)
        if (data.status == 201) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
          // hide()
        } else {
         
          ShowToast(data.data.error, Severty.ERROR)
          refresh()
          hide()

        }
      },
      onError: (data) => {
        console.log(data.response.data , 3799999)
      
        ShowToast(data.response.data.data.error[0], Severty.ERROR)
        refresh()
        setLoading(false)
      },
      // onErrorSubmit: (data) => {
      //   console.log(data)
      // }


    })
    // console.log(values)
  };

  const strongPasswordValidator = (rule, value, callback) => {
    // Define a regular expression to check for a strong password.
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    if (!value) {
      callback('Please input the password!');
    } else if (!strongPasswordRegex.test(value)) {
      callback(
        'Password must be at least 8 characters long and contain at least one letter, one number, and one special character (@, $, !, %, *, ?, or &).'
      );
    } else {
      callback(); // Validation passed
    }
  };
  

  return (
    <Modal
      visible={show}
      title={`${data ? 'Edit Photographer' : 'Create a new Photographer'} `}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish ={onCreate} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input the email!" }
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            { required: true, message: "Please input first name!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            { required: true, message: "Please input last name!" }
          ]}
        >
          <Input />
        </Form.Item>
        {
          data? "": <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, validator: strongPasswordValidator }
          ]}
        >
          <Input.Password />
        </Form.Item>
        }
        <Form.Item
          label="Company Name"
          name="companyName"
          rules={[
            { required: true, message: "Please input the name!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
  label="Mobile Number"
  // name="mobileNo"
  rules={[
    { required: true, message: "Please input the Mobile number!" },
    // Add additional phone number validation rules here
  ]}
>
  <PhoneInput
    inputProps={{
      name: "mobileNo",
      autoFocus: false,
    }}
            isValid={(value, country) => {
              if (value.match(/1234/)) {
                return 'Invalid value: ' + value + ', ' + country.name;
              } else if (value.match(/^(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/)) {
                return 'Invalid value: ' + value + ', ' + country.name;
              } else {
                return true;
              }
            }}
            country={"ca"} value={mobileNumber ? ((mobileNumber.country_code ? mobileNumber.country_code : "+1") + (mobileNumber.mobileNo ? mobileNumber.mobileNo : '')) : ''} onChange={handleChange} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Photographer;
