import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Typography,
  Button,
  Tooltip,
} from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Main from "../components/layout/Main";
import useRequest from "../hooks/useRequest";
import { useNavigate } from "react-router";
import EnquiryIcon from "../assets/images/Enquiry.svg";
import BookingIcon from "../assets/images/Booking.svg";
import EarningIcon from "../assets/images/Earning.svg";

import { ShowToast, Severty } from "../helper/toast";

function Home() {
  const { Title, Text } = Typography;

  const [dashboard, setDashboard] = useState();
  const [barData, setBarData] = useState(false);
  const [dashPhotographer, setdashPhotographer] = useState();
  const [graphData, setGraphData] = useState({});
  const [bookingGraphData, setBookingGraphData] = useState({});

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
  );

  const profile = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill="#fff"
      ></path>
      <path
        d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
        fill="#fff"
      ></path>
      <path
        d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
        fill="#fff"
      ></path>
      <path
        d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const enquiry = [
    <img src={EnquiryIcon} />
    // <svg
    //   width="22"
    //   height="22"
    //   viewBox="0 0 20 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   key={0}
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
    //     fill="#fff"
    //   ></path>
    // </svg>,
  ];
  const booking = [
    <img src={BookingIcon} />
  ];
  const earning = [
    <img src={EarningIcon} />
  ];

  const count = [
    {
      today: "Total Photographer",
      title: `${dashboard ? dashboard.totalPhotographer : 0}`,
      persent: "+20%",
      icon: profile,
      bnb: "bnb2",
      // url: "/photographer",
    },
    {
      today: "Total Enquiries",
      title: `${dashboard ? dashboard.totalEnquiry : 0}`,
      persent: "-20%",
      icon: enquiry,
      bnb: "redtext",
      // url: "/enquiry",
    },
    {
      today: "Total Booking",
      title: `${dashboard ? dashboard.totalBooking : 0}`,
      persent: "-20%",
      icon: booking,
      bnb: "redtext",
      // url: "/enquiry",
    },
    {
      today: "Total Earning",
      title: `${dashboard ? dashboard.totalEarning : 0}`,
      persent: "-20%",
      icon: earning,
      bnb: "redtext",
      // url: "/enquiry",
    },
  ];

  const bookingMonthlyCount = (data) => {
    const Pmonths = data?.bookingGraph?.bookingMonthlyCount?.map(item => item?.month);
    const Pcounts = data?.bookingGraph?.bookingMonthlyCount?.map(item => item?.count);
    const bookingChartData = {
      labels: Pmonths,
      datasets: [
        {
          label: 'Booking Monthly Count',
          data: Pcounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
    setBookingGraphData(bookingChartData)
  }
  const bookingWeeklyCount = () => {
    const Pmonths = dashboard?.bookingGraph?.bookingWeeklyCount?.map(item => item?.week);
    const Pcounts = dashboard?.bookingGraph?.bookingWeeklyCount?.map(item => item?.count);
    const bookingChartData = {
      labels: Pmonths,
      datasets: [
        {
          label: 'Booking Weekly Count',
          data: Pcounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
    setBookingGraphData(bookingChartData)
  }
  const bookingYearlyCount = () => {
    const Pmonths = dashboard?.bookingGraph?.bookingYearlyCount?.map(item => item?.year);
    const Pcounts = dashboard?.bookingGraph?.bookingYearlyCount?.map(item => item?.count);
    const bookingChartData = {
      labels: Pmonths,
      datasets: [
        {
          label: 'Booking Yearly Count',
          data: Pcounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
    setBookingGraphData(bookingChartData)
  }

  const photographerMonthlyCount = (data) => {
    const Pmonths = data?.photographerGraph?.photographerMonthlyCount?.map(item => item?.month);
    const Pcounts = data?.photographerGraph?.photographerMonthlyCount?.map(item => item?.count);
    const photographerChartData = {
      labels: Pmonths,
      datasets: [
        {
          label: 'Photographer Monthly Count',
          data: Pcounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
    setGraphData(photographerChartData)
  }
  const photographerWeeklyCount = () => {
    const Pmonths = dashboard?.photographerGraph?.photographerWeeklyCount?.map(item => item?.week);
    const Pcounts = dashboard?.photographerGraph?.photographerWeeklyCount?.map(item => item?.count);
    const photographerChartData = {
      labels: Pmonths,
      datasets: [
        {
          label: 'Photographer Weekly Count',
          data: Pcounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
    setGraphData(photographerChartData)
  }
  const photographerYearlyCount = () => {
    const Pmonths = dashboard?.photographerGraph?.photographerYearlyCount?.map(item => item?.year);
    const Pcounts = dashboard?.photographerGraph?.photographerYearlyCount?.map(item => item?.count);
    const photographerChartData = {
      labels: Pmonths,
      datasets: [
        {
          label: 'Photographer Yearly Count',
          data: Pcounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
    setGraphData(photographerChartData)
  }

  const columns = [
    {
      title: "Name",
      render: (_, { firstName, lastName }) => {
        return (
          <div>
            {firstName + " " + lastName}
          </div>


        );
      },
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNo",
      key: "mobileNo",
    },
    {
      title: "Registartion date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {

        return (
          new Date(created_at).toLocaleString().slice(0, 9)
        );
        // { is_active ? "Active" : "Inactive" }

      },

    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Tooltip placement="top" title={"View"}>
            <Button className="tableBtn" onClick={() => {
              navigate(`photographer/${record._id}/view-photographer`)
              // setSelected(record)
              // setVisible(true)
            }}>
              <i class="fa fa-eye" aria-hidden="true"></i>
            </Button>
          </Tooltip>
        )
      }
    }
  ];

  useEffect(() => {

    setLoading(true);
    request({
      url: `/admin/dashboard/data`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setdashPhotographer(data?.data?.photographers)
        // ShowToast(data.message, Severty.SUCCESS)
        setDashboard(data?.data);
        photographerMonthlyCount(data?.data)
        bookingMonthlyCount(data?.data)
        setTimeout(() => {
          setBarData(true);
        }, 3000);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  return (
    <Main>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" >
                    <Col xs={18}>
                      <Link to={c.url}>
                        <span>{c.today}</span>
                        <Title level={3} className="" style={{ margin: 0 }}>{c.title}</Title>
                      </Link>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box d-flex align-items-center justify-content-center">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={12}>
          <Col xs={24} xl={12}>
            <Card className="graphCard"
              title={
                <div className="graphHeader">
                  <div className="graphTitle">Photographer Count</div>
                  <div className="buttonGroup">
                    {/* <h2>Photographer Monthly Count</h2> */}
                    <Button className="graphBtn" onClick={photographerWeeklyCount}>Week</Button>
                    <Button className="graphBtn" onClick={() => photographerMonthlyCount(dashboard)}>Month</Button>
                    <Button className="graphBtn" onClick={photographerYearlyCount}>Year</Button>
                  </div>
                </div>
              }

            >
              <div className="grapOuter">
                {barData && <Bar data={graphData} />}
              </div>
            </Card>
          </Col>
          <Col xs={24} xl={12} className="mt-lg">
            <Card className="graphCard"

              title={
                <div className="graphHeader">
                  <div className="graphTitle">Bookings Data</div>
                  <div className="buttonGroup">
                    <Button className="graphBtn" onClick={bookingWeeklyCount}>Week</Button>
                    <Button className="graphBtn" onClick={() => bookingMonthlyCount(dashboard)}>Month</Button>
                    <Button className="graphBtn" onClick={bookingYearlyCount}>Year</Button>
                  </div>
                </div>
              }
            >
              <div>
                {barData && <Bar data={bookingGraphData} />}
              </div>
            </Card>
          </Col>
        </Row>


        <div className="tabled margin-cls">
          <Row gutter={[12]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Recent Photographer"
              >
                <div className="table-responsive">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dashPhotographer}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Main>
  );
}

export default Home;
