import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Tag,
  Space,Select
} from "antd";
import { SearchOutlined, DownOutlined, SmileOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";

import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect, useRef } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
import useDebounce from "../hooks/useDebounce";
const Search = Input.Search;
function EnquiryForm() {
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchedColumn, setSearchedColumn] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const debouncedSearchText = useDebounce(searchText, 300);
  const searchInput = useRef(null);
  const { confirm } = Modal;
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const showConfirm = (record,type) => {

    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?? </Button>,
        onOk() {
          statusChange(record,type)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 500);

  };

  const statusChange = (record,type) => {
    console.log(record, 155)
    const payload = {}
    console.log(payload, 164)
    setLoading(true);
    request({
      url: `/admin/photographer/enquiry/list`,
      method: 'put',
      data: payload,
      onSuccess: (data) => {
        // Reload the window

        setRefresh(prev => !prev);
        setLoading(false)

      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const columns = [
    {
      title: "Label",
      render: (_, { label }) => {
        return (
          <div>
            {label}
          </div>


        );
      },
      key: "label"
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Answer Type",
      dataIndex: "answer_type",
      key: "answer_type",
    },
    {
      title: "Options",
      dataIndex: "option",
      key: "option",
    },
    {
      title: "STATUS",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_active === value,
      render: (_, { is_active, _id}) => {
        let color = is_active ? 'green' : 'red';
        return (
          <Tag color={color} key={is_active} onClick={(e) => showConfirm(_id,"is_active")} >
            {is_active ? "Active" : "Inactive"}
          </Tag>
        );
        // { is_active ? "Active" : "Inactive" }

      },
    },
    {
      title: "Created date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          new Date(created_at).toLocaleDateString()
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
          <Button onClick={() => {
            setSelected(record)
            setVisible(true)
          }}>
            Edit
          </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: `/admin/enquiry/list`,
      method: 'GET',
      onSuccess: (data) => {
        console.log("defrgtyhrgfed",data);
        setLoading(false)
        // ShowToast(data.message, Severty.SUCCESS)
        let rowData =[];
        data?.data?.list?.docs?.map((data) => {
            console.log(data.options, 284)
            data.options ?  data["option"] = data.options.join(","): data["option"] = null
            rowData.push(data)
        })
        setList(rowData)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const handleChange = (pagination, filters) => {
    console.log(pagination, filters);
    fetchData(pagination, filters);
  }                                                                                         

  return (
    <Main>
      <div className="tabled">
        <Row>
          <Col xs="24" xl={24}>
          <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Enquiry Form Management"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    onPressEnter={onSearch}
                  />
                  <Button onClick={(e) => setVisible(true)}>Add Form </Button>
                </>
              }
            >

              <div className="table-responsive">

                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={pagination}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>


          </Col>
        </Row>
      </div>
      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [mobileNumber, setMobileNumber] = useState({
    mobileNo: '',
    country_code: '',
  });


  const [form] = Form.useForm();
  const { request } = useRequest()

  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState("")
  const [answerType, setAnswerType] = useState("")

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
    setMobileNumber({
      mobileNo: data.mobileNo,
      country_code: data.countryCode
    })
  }, [data])


const { Option } = Select;


const handleChangeItem = (value) => {
    setItem(value)
    console.log(`Selected value: ${value}`);
  };
const handleChangeAnswer = (value) => {
    setAnswerType(value)
}

  const onCreate = ( values) => {
    const { label, type, answer_type , options } = values
    const payload = {}
    console.log(options, "type")
    console.log(values, "values")

    payload["label"] = label
    payload["type"] = item
    payload["answer_type"] = answerType
    payload["options"] =  options

    request({
      url: `${data ? `/admin/enquiry/editEnquiry/${data._id}` : `/admin/enquiry/add-form`}`,
      method: `${data ? 'PUT' : 'POST'}`,
      data: payload,
      onSuccess: (data) => {
        console.log(data)
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
          // hide()

        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Edit Form' : 'Create a new Form'} `}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish ={onCreate } layout="vertical">
        <Form.Item
          label="Label"
          name="label"
          rules={[
            { required: false, message: "Please input the label!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[
            { required: false, message: "Please input Type!" }
          ]}
        >

        <Select value={item} onChange={handleChangeItem}>
            <Option value="Textbox">Textbox</Option>
            <Option value="Checkbox">Checkbox</Option>
            <Option value="Selectbox">Selectbox</Option>
            <Option value="Textarea">Textarea</Option>

        </Select>
          {/* <Input /> */}
        </Form.Item>
        <Form.Item 
          label="Answer Type"
          name="answer_type"
          rules={[
            { required: false, message: "Please input answer type!" }
          ]}
        >
        <Select value={answerType} onChange={handleChangeAnswer}>
            <Option value="Mandatory">Mandatory</Option>
            <Option value="Optional">Optional</Option>

        </Select>   
             </Form.Item>
         <Form.List name="options" label = "Options">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={`Input ${index + 1}`}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  noStyle
                >
                  <Input />
                </Form.Item>
                {fields.length > 1 && (
                  <Button
                    type="danger"
                    onClick={() => remove(field.name)}
                    style={{ marginLeft: '8px' }}
                  >
                    -
                  </Button>
                )}
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block>
                +
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      </Form>
    </Modal>
  )
}

export default EnquiryForm;
