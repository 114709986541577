import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/Vector.png";
import DashboardIcon from "../../assets/images/dashboard.svg";
import icon1 from "../../assets/images/side-ic1.svg";
import icon2 from "../../assets/images/side-ic2.svg";
import icon3 from "../../assets/images/side-ic3.svg";
import icon4 from "../../assets/images/email.svg"
import Profile from "../../assets/images/profile.svg";
function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  console.log(page,"page")

  return (
    <>

      <div className="brand">
        <img src={logo} alt="" />
        <span>Flomatic</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <img src={DashboardIcon} />
            </span>

            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/photographer">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <img src={icon1} />
            </span>
            <span className="label">Photographer Manager</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="3">
          <NavLink to="/enquiry">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <img src={icon1} />
            </span>
            <span className="label"> Enquiry Form Manager</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="4">
          <NavLink to="/subscription">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <img src={icon2} />
            </span>
            <span className="label">Subscription Manager</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="/content">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <img src={icon3} />
            </span>
            <span className="label">Content</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/email-templates">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <img src={icon4} />
            </span>
            <span className="label">Email Templates</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/subscription-earning">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <img src={icon4} />
            </span>
            <span className="label">Subscription Earning</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="8">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              <img src={Profile} />
            </span>
            <span className="label">Account</span>
          </NavLink>
        </Menu.Item>
      </Menu>

    </>
  );
}

export default Sidenav;
