import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';
import React, { useContext, Suspense } from 'react';
import Home from "./pages/Home";
import Photographer from "./pages/Photographer";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import PrivateRoute from './components/PrivateRoute'
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Error from './pages/Error';
import Subscription from './pages/Subscription';
import Content from './pages/Content';
import EmailTemplates from './pages/EmailTemplates';
import ViewEmailTemplate from './pages/ViewEmailTemplate';

import ViewPhotographer from './pages/ViewPhotographer';
import EnquiryForm from './pages/EnquiryForm';
import SubscriptionEarning from './pages/SubscriptionEarning';
import ForgotPassword from './pages/ForgotPassword';
window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {

  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  return (
    <Routes >
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>}
      >
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/photographer" element={<Photographer />} />
        <Route exact path="/photographer/:id/view-photographer" element={<ViewPhotographer />} />
        <Route exact path="/enquiry" element={<EnquiryForm />} />
        <Route exact path="/subscription" element={<Subscription />} />
        <Route exact path="/subscription-earning" element={<SubscriptionEarning />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/email-templates/:id/view-email" element={<ViewEmailTemplate />} />
        <Route exact path="/content" element={<Content />} />
        <Route exact path="/email-templates" element={<EmailTemplates />} />
      </Route>
      <Route path="*" element={<Error />} />
    </Routes>
  )
}

const Layout = () => {
  return (<> <Outlet />  </>
  )
}


export default App;
