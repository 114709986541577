import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Modal,
    Form,
    Tooltip,

    Input,
    Tag,
    Select,
    Checkbox
  } from "antd";  
  
  import { ExclamationCircleOutlined } from '@ant-design/icons';
  import 'react-phone-input-2/lib/style.css'
  import React, { useState, useContext, useEffect } from "react";
  import useRequest from "../hooks/useRequest";
  import { ShowToast, Severty } from "../helper/toast";
  import Main from "../components/layout/Main";
import moment from "moment";
  const { Option } = Select;
  
  function SubscriptionEarning() {
    const { request } = useRequest()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [selected, setSelected] = useState();
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

   const {confirm} = Modal
    const [visible, setVisible] = useState(false);

  const [sort, setSort]= useState(false)
    const showConfirm = (record) => {
  
      setTimeout(() => {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: <Button >Are you sure you want change the status ?? </Button>,
          onOk() {
            statusChange(record)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }, 500);
    };
    const statusChange = (record) => {
      console.log(record, 155)
      const payload = {}
      // setLoading(true);
      request({
        url: `/admin/subscription/data/statusChange/${record}`,
        method: 'put',
        data: payload,
        onSuccess: (data) => {
          // Reload the window
  
          setRefresh(prev => !prev);
          // setLoading(false)
  
        },
        onError: (error) => {
          console.log(error)
          ShowToast(error, Severty.ERROR)
        }
      })
    };

   
    const columns = [

        {
            title: "Name",
            render: (_, { photoghrapherData }) => {
              return (
                <div>
                  {photoghrapherData[0]?.firstName + " " + photoghrapherData[0]?.lastName}
                </div>
      
      
              );
            },
            key: "name"
          },
          {
            title: "Email",
            render: (_, { photoghrapherData }) => {
              return (
                <div>
                  {photoghrapherData[0]?.email}
                </div>
      
      
              );
            },
            key: "email"
          },{
            title: "Mobile Number",
            render: (_, { photoghrapherData }) => {
              return (
                <div>
                  {photoghrapherData[0]?.mobileNo}
                </div>
      
      
              );
            },
            key: "mobileNo"
          },
          {
            title: "Company Name",
            render: (_, { photoghrapherData }) => {
              return (
                <div>
                  {photoghrapherData[0]?.companyName}
                </div>
      
      
              );
            },
            key: "companyName"
          },
          {
            title: "Subscription Name",
            render: (_, { subscriptionData }) => {
              return (
                <div>
                  {subscriptionData[0]?.subscriptionName}
                </div>
      
      
              );
            },
            key: "subscriptionName"
          },
      {
        title: "Price",
        dataIndex: "amount",
        key: "amount",
        // width: "20%",
      },
      {
        title: "Validity",
        dataIndex: "validity",
        key: "validity",
        // width: "20%",
      },
    //   {
    //     title: "Additional Details",
    //     dataIndex: "description",
    //     key: "description",
    //     width: "20%",
    //   },
      {
        title: "STATUS",
        filters: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'Inactive',
            value: false,
          },
        ],
        onFilter: (value, record) => record.is_active === value,
        key: "is_active",
        dataIndex: "is_active",
        render: (_, { is_active, _id }) => {
          let color = is_active ? 'green' : 'red';
          return (
            <Tag color={color} key={is_active}  >
              {is_active ? "Active" : "Inactive"}
            </Tag>
          );
          // { is_active ? "Active" : "Inactive" }
  
        },
      },
      {
        title: "Started date",
        key: "start_date",
        dataIndex: "start_date",
        render: (_, { start_date }) => {
  
          return (
            moment(start_date).format("YYYY-MM-DD")
            // new Date(start_date).toLocaleString().slice(0, 9)
          );  
        },
  
      },
      {
        title: "End date",
        key: "end_date",
        dataIndex: "end_date",
        render: (_, { end_date }) => {
  
          return (
            moment(end_date).format("YYYY-MM-DD")
          );  
        },
  
      },
      {
        title: "Action",
        render: (_, record) => {
          return (
            <>
  
        <Tooltip placement="top" title={"Edit"}>
            <Button className="tableBtn" onClick={() => {
              setSelected(record)
              setVisible(true)
            }}>
            <i class="fa fa-pencil" aria-hidden="true"></i>
            </Button>
        </Tooltip>

            </>
          );
        },
      },
    ];
    useEffect(() => {
      setLoading(true)
      // getSubscription()
      fetchData(pagination,sort)
    }, [ refresh])
    const handleChange = (pagination,filters) => {
      // console.log(filters, "hhhh")
      fetchData(pagination,sort,filters);
    }
    const fetchData = (pagination,sort,filters) => {
      const filterActive = filters ? filters.is_active : null

      request({
        url:`/admin/subscription/data/dataList?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&sort=${sort}`,
        method: 'GET',
        onSuccess: (data) => {
          setLoading(false)
          // ShowToast(data.message, Severty.SUCCESS)
          setList(data?.data?.list?.docs)
          setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))

        },
        onError: (error) => {
          console.log(error)
          ShowToast(error, Severty.ERROR)
        }
      })
    }
    const onCheck = (e) => {
      if(e.target.checked){
        setSort(true);
        fetchData(pagination,e.target.checked);
      }else{
        setSort(false);
        fetchData(pagination,e.target.checked);
      }
    };

    return (
      <Main>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24 customCrd"
                title={
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="title">Subscription Earning Manager</div>
                    <Checkbox onChange={onCheck}>Expiring Soon</Checkbox>
                  </div>
                }
              >


                <div className="table-responsive">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={pagination}
                    onChange = {handleChange}
                    className="ant-border-space"
                  />
                </div>
              </Card>
  
  
            </Col>
          </Row>
        </div>
        {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      </Main>
    );
  }
  
  const AddFrom = ({ show, hide, data, refresh }) => {
    const { request } = useRequest()
    const [loading, setLoading] = useState(false)
    const [subslist, setSubsList] = useState([])

    const [form] = Form.useForm();
    const onCreate = (values) => {
      const { subscription, photographerId
      } = values
      const payload = {}
      setLoading(true)
      payload.subscriptionId= subscription
      payload.photographerId = data.photoghrapherData[0]._id
     
      request({
        url: `/admin/subscription/data/changeSubscription`,
        method: 'POST',
        data: payload,
        onSuccess: (data) => {
          setLoading(false)
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS)
            hide()
            refresh()
            // hide()
          } else {
            ShowToast(data.message, Severty.ERROR)
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR)
          setLoading(false)
        },
      })
    };

    const getSubscription = () => {
      // setLoading(true);
      request({
        url: '/admin/subscription/list',
        method: 'GET',
        onSuccess: (data) => {
          setLoading(false)
          // ShowToast(data.message, Severty.SUCCESS)
          let subsArray = []
          data.data.list.map((v) => {
           if( v.is_active == true){
            subsArray.push(v)

           }
          })
          setSubsList(subsArray)
          console.log(subsArray, 944)
        },
    
        onError: (error) => {
          console.log(error)
          ShowToast(error, Severty.ERROR)
        }
      })
    };


  

    useEffect(() => {
      console.log(data, 3733)
      getSubscription();
      if (!data) return
      form.setFieldsValue({ ...data,subscription:data.subscriptionData[0]._id});
    }, [data])
  
    return (
      <Modal
        visible={show}
        title="Create a new Subscription"
        okText="Ok"
        onCancel={hide}
        okButtonProps={{
          form: 'create',
          htmlType: 'submit',
          loading: loading,
        }}
      >
        {console.log(subslist,":syy")}
                      <Form 
                      id="create" form={form} onFinish ={onCreate} layout="vertical"
                      >
                       <Form.Item
              name="subscription"
              label="subscription"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please select your subscription!',
                },
              ]}
            >
              <Select placeholder="Please select a subscription">
              {subslist.length > 0 && subslist.map((events, index) => (
                    <Option key={index} value={events._id}>
                      {events.subscriptionName}({events.amount})
                    </Option>
                  ))}
              </Select>
            </Form.Item>
                        {/* <div className="row selectPackage">
                          {subslist.length > 0
                            ? subslist.map((item, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className={
                                        item._id === data.subscriptionData._id
                                          ? "packageItem subscriptionItem mb-3 p-3 rounded-4 bg-grey"
                                          : "packageItem subscriptionItem mb-3 p-3 rounded-4 bg-white"
                                      }
                                    >
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center gap-3 mb-3">
                                          <Form.Item
                                            type="radio"
                                            name="subscriptionId"
                                            className="customRadio"
                                            value={item._id}
                                          
                                          />
                                          <div className="packageHeader d-flex align-items-center justify-content-between w-100 mb-0">
                                            <div className="title">
                                              {item?.subscriptionName}
                                            </div>
                                            <div className="price">
                                              {"$"}
                                              {item?.amount}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="content text-start">
                                          {item?.description && item.description.length > 150
    ? item.description.substring(0, 150) + "..." 
    : item.description}                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="validity text-end mt-2">
                                            Validity : {item?.validity}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                       
                        </div> */}
                      </Form>
                    {/* )}
                  </Formik> */}
      </Modal>
    )
  }
  
  export default SubscriptionEarning;
  