import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Tooltip,
  Form,
  Input,
  Tag,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";
const { Option } = Select;
const Search = Input.Search;

function Subscription() {
  const { request } = useRequest()
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [selected, setSelected] = useState();
 const {confirm} = Modal
  const [visible, setVisible] = useState(false);

  const showConfirm = (record,type) => {

    // setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ? </Button>,
        onOk() {
          statusChange(record,type)
        },
        onCancel() {
          console.log('Cancel');
        },
      // });
    }, 500);
  };
  const showDelete = (record,type) => {

    // setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want to delete ? </Button>,
        onOk() {

          statusChange(record,type)
        },
        onCancel() {
          console.log('Cancel');
        },
      // });
    }, 500);

  };

  const statusChange = (record,type) => {
    console.log(record, 155)
    const payload = {}
    console.log(type, 164)
    // setLoading(true);
    request({
      url: `/admin/subscription/statusChange/${record}/${type}`,
      method: 'put',
      data: payload,
      onSuccess: (data) => {
        // Reload the window

        setRefresh(prev => !prev);
        // setLoading(false)

      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "subscriptionName",
      key: "subscriptionName",
      // width: "20%",
    },
    {
      title: "Price",
      dataIndex: "amount",
      key: "amount",
      // width: "20%",
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
      // width: "20%",
    },
    {
      title: "Additional Details",
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: "STATUS",
      key: "is_active",
      dataIndex: "is_active",
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (
          <Tag color={color} key={is_active} onClick={(e) => showConfirm(_id,"is_active")} >
            {is_active ? "Active" : "Inactive"}
          </Tag>
        );
        // { is_active ? "Active" : "Inactive" }

      },
    },
    {
      title: "Created date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {

        return (
          new Date(created_at).toLocaleString().slice(0, 9)
        );
        // { is_active ? "Active" : "Inactive" }

      },

    },
    {
      title: "Action",
      render: (_, record) => {

        return (
          <>
          <Tooltip placement="top" title={"Edit"}>
            <Button className="tableBtn" onClick={() => {
              setSelected(record)
              setVisible(true)
            }}>
            <i class="fa fa-pencil" aria-hidden="true"></i>
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={"Delete"}>

          <Button className="tableBtn" onClick={() => {
                showDelete(record._id, "is_delete")
              }}>
              <i class="fa fa-trash-o" aria-hidden="true"></i>
              </Button>
              </Tooltip>
              </>
        );
        // { is_active ? "Active" : "Inactive" }

      },

    },
  ];
  useEffect(() => {
    setLoading(true)
    request({
      url: '/admin/subscription/list',
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        // ShowToast(data.message, Severty.SUCCESS)
        setList(data.data.list)

      },
    })
  }, [refresh])
  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Subscription Manager"
              extra={
                <div className="extraDetail">
                  {/* <Search
                    size="large"
                    onChange={onSearch}
                    onPressEnter={onSearch}
                    placeholder=" Search by Name....."
                  /> */}
                  <Button onClick={(e) => setVisible(true)}>Add Subscription</Button>
                </div>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>


          </Col>
        </Row>
      </div>
      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const onCreate = (values) => {
    const {         subscriptionName, amount, validity, description
    } = values
    const payload = {}
    setLoading(true)
    payload.subscriptionName = subscriptionName
    payload.amount = amount
    payload.validity = 1
    payload.description = description
    request({
      url: `${data ? `/admin/subscription/edit-subscription/${data._id}` : `/admin/subscription/add-subscription`}`,
      method: `${data ? 'PUT' : 'POST'}`,
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
          // hide()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };
  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data});
  }, [data])

  return (
    <Modal
      visible={show}
      title={`${data ? "Edit Subscription": "Create a new Subscription"}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form
        id="create"
        form={form}
        layout="vertical"
        onFinish={onCreate}
      >
        <Form.Item
          name="subscriptionName"
          label="Type"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please input type!',
            },
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Price ($)"
          name="amount"
          rules={[
            { required: true, message: "Please input the price!" },
            {
              pattern: /^[0-9]+$/,
              message: "Please enter only numbers."
            }
          ]}
        >
          <Input/>
        </Form.Item>
        
        <Form.Item
          name="validity"
          label="Validity(months)"
        
        >
          {data ?  <Input  defaultValue={1}
          />: <Input  defaultValue={1} disabled/>}
         
        </Form.Item>

        <Form.Item
          name="description"
          label="Details"
          >
<Input/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Subscription;
