import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Card,
  Input,
} from "antd";

import Main from "../components/layout/Main";
import useRequest from "../hooks/useRequest";
import { useParams } from "react-router";
import logo from "../assets/images/Vector.png";
import { Link } from "react-router-dom";

function ViewEmailTemplate() {
  const [selected, setSelected] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const params = useParams();

  useEffect(() => {
    request({
      url: `/admin/template/template/${params.id}`,
      method: "GET",
      onSuccess: (data) => {
        setSelected(data?.data?.data)
      },
    });
  }, [refresh]);

  return (
    <Main>

      <Card
        bordered={false}
        className="mb-24 email-template"
        title={
          <>
            <h6 className="font-semibold">Email Preview</h6>
          </>
        }
      >

<div
      dangerouslySetInnerHTML={{ __html: selected?.description }}
    />
                  
      </Card>
    </Main>
  );
}

export default ViewEmailTemplate;
