import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Switch,
  Modal
} from "antd";
import signinbg from "../assets/images/flomaticLogo.png";
import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import passHide from "../assets/images/passHide.png";
import passShow from "../assets/images/passShow.png";

import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;


const SignIn = () => {
  const [refresh, setRefresh] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [otpModal, setOtpModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const [remember, setRemember] = useState();
  const [classChecked, setClassChecked] = useState()
  const [form] = Form.useForm();
  const handleCheckboxChange = (e) => {
    setRemember(e.target.checked);
    if (remember == true) {
      setClassChecked("passClass")
    } else {
      setClassChecked("passClass Checked")

    }
  };
  const onFinish = (values) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    const { email, password } = values
    if (!email) return ShowToast('Please enter email to sign in', Severty.ERROR)

    const payload = { password }
    if (!email) return ShowToast('Please enter valid email ', Severty.ERROR)
    if (rememberMe) {
      // Store user login information in local storage
      localStorage.setItem("email", values.email);
      localStorage.setItem("password", values.password);
    }
    setLoading(true)
    payload.email = email
    request({
      url: '/admin/auth/login',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          console.log(data);
          setIsLoggedIn(true);
          if (remember === true) {
            localStorage.setItem("email", email);
            localStorage.setItem("pass", password);
            localStorage.setItem("remember", true);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("pass");
            localStorage.removeItem("remember");
            setRemember(false)
          }
          localStorage.setItem('token', data.data.token)
          localStorage.setItem('userProfile', JSON.stringify(data.data.user))
          ShowToast(data.message, Severty.SUCCESS)
          setUserProfile(data.data)
          navigate('/')
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  }

  // useEffect(() => {
  //   const payload = JSON.parse(localStorage.getItem('remember_me'))
  //   if (!payload) return
  // }, [])
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");

    if (storedEmail && storedPassword) {
      form.setFieldsValue({ email: storedEmail, password: storedPassword });
    }
  }, [refresh]);

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('pass');

    if (savedEmail && savedPassword) {
      form.setFieldsValue({ email: savedEmail, password: savedPassword });
      setRemember(true);
    } else {
      setRemember(false);
    }

  }, [refresh]);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleForgotPassword = () => {
    setVisible(true);
  };

  const handleResetPassword = (email) => {
    // Send the OTP to the user's email
    let payload = {};
    payload.email = email.email
    request({
      url: '/admin/auth/forgot-password',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setVisible(false);
        setOtpModal(true);
        setSelected({ email });
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleVerifyOTP = (values) => {
    const { email, otp } = values
    // Verify the OTP entered by the user
    let payload = {};
    payload.email = email
    payload.otp = otp
    request({
      url: '/admin/auth/verify-otp',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setOtpModal(false);
        setSelected(null);
        setVisible(false);
        setResetModal(true);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleReset = (values) => {
    const { email, newPassword } = values
    // Reset the password with the new password entered by the user
    let payload = {};
    payload.email = email
    payload.password = newPassword
    request({
      url: '/admin/auth/reset-password',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setResetModal(false);
        setRefresh(prev => !prev);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleCancelReset = () => {
    setResetModal(false);
  };

  return (
    <>
      {loading && <Loader />}
      <Layout
        className="layout-default layout-signin"
        style={{ backgroundColor: "white", height: "100%" }}

      >
        <Content className="signin signinOuter">

          <div className="signin-box">
            <div className="signup-logo">
              <img src={signinbg} alt="" />
            </div>
            <Row justify="space-around">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
              >
                <div className="signup-form">
                  <Title className="mb-15">Sign In</Title>
                  <Title className="font-regular text-muted" level={5}>
                    Enter your email and password to sign in                </Title>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      label="Email"
                      name="email"
                      value={localStorage.getItem("email")}
                      rules={[
                        {
                          required: true,
                          message: "Please enter email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                      className="username loginPass"
                      label="Password"
                      name="password"
                      value={localStorage.getItem("pass")}
                      rules={[
                        {
                          required: true,
                          message: "Please enter password!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="Password" onPaste={(event) => event.preventDefault()}
                        onCopy={(event) => event.preventDefault()} />
                      {/* <a className="btnPassShowHide">
                      <img className="" src={passHide} alt="" />
                      <img className="" src={passShow} alt="" />
                    </a> */}
                    </Form.Item>
                    {/* <div className="form-group remember"> */}


                    <div className="forgot-pass">
                      <Form.Item
                        name="remember"
                        className="aligin-center"
                        valuePropName="checked"
                      >
                        <Switch checked={rememberMe}
                          onChange={(checked) => setRememberMe(checked)} />
                        Remember me
                      </Form.Item>
                      <a href="/forgot-password">Forgot Password</a>

                    </div>

                    {/* <Form.Item
                        name="remember"
                        className="aligin-center"
                        valuePropName="checked"
                      >
                        <Switch defaultChecked onChange={onChange} />
                        Remember me
                      </Form.Item> */}

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        SIGN IN
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
              {/* <Col
                className="sign-img"
                style={{ padding: 12 }}
                xs={{ span: 24 }}
                lg={{ span: 12 }}
                md={{ span: 12 }}
              >
                <img src={signinbg} alt="" />
              </Col> */}
            </Row>
          </div>
        </Content>
        <Modal
          visible={visible}
          title="Forgot Password"
          okText="Ok"
          onCancel={() => setVisible(false)}
          okButtonProps={{
            form: 'forget-pasword',
            htmlType: 'submit',
            loading: loading,
          }}
        >
          {/* <Modal
        visible={visible}
        title="Forgot Password"
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleResetPassword(selected.email)}
          >
            Send OTP
          </Button>,
        ]}
      > */}
          <Form id="forget-pasword" form={form} onFinish={handleResetPassword} layout="vertical">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Form>
        </Modal>

        <Modal
          visible={otpModal}
          title="Verify OTP"
          okText="Ok"
          onCancel={() => setOtpModal(false)}
          okButtonProps={{
            form: 'verify-otp',
            htmlType: 'submit',
            loading: loading,
          }}
        >
          {/* <Modal
        visible={otpModal}
        title="Verify OTP"
        onCancel={() => setOtpModal(false)}
        footer={[
          <Button key="back" onClick={() => setOtpModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleVerifyOTP(selected.email, selected.otp)}(
          >
            Verify
          </Button>,
        ]}
      > */}
          <Form id="verify-otp" form={form} onFinish={(e) => handleVerifyOTP(e)} layout="vertical">
            <Form.Item
              label="OTP"
              name="otp"
              rules={[
                {
                  required: true,
                  message: 'Please enter the OTP',
                },
                {
                  len: 4,
                  message: 'OTP must be 4  digits',
                },
              ]}
            >
              <Input type="number" maxLength={4} placeholder="Enter OTP" />
            </Form.Item>
            {/* <Form.Item
          label="OTP"
          name="otp"
          rules={[
            {
              required: true,
              message: "Please input the OTP!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

          </Form>
        </Modal>

        <Modal
          visible={resetModal}
          title="Reset Password"
          okText="Ok"
          onCancel={handleCancelReset}
          okButtonProps={{
            form: 'reset-password',
            htmlType: 'submit',
            loading: loading,
          }}
        >
          {/* <Modal
        visible={resetModal}
        title="Reset Password"
        onCancel={handleCancelReset}
        footer={[
          <Button key="back" onClick={handleCancelReset}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleReset(selected.email, selected.otp, selected.newPassword)}
          >
            Reset
          </Button>,
        ]}
      > */}
          <Form id="reset-password" form={form} onFinish={(e) => handleReset(e)} layout="vertical">
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirm_new_password"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                { required: true, message: "Please input the confirm password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
        {/* <Footer>
            <Menu mode="horizontal">
              <Menu.Item>Company</Menu.Item>
              <Menu.Item>About Us</Menu.Item>
              <Menu.Item>Teams</Menu.Item>
              <Menu.Item>Products</Menu.Item>
              <Menu.Item>Blogs</Menu.Item>
              <Menu.Item>Pricing</Menu.Item>
            </Menu>
            <Menu mode="horizontal" className="menu-nav-social">
              <Menu.Item>
                <Link to="#">{<DribbbleOutlined />}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="#">{<TwitterOutlined />}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="#">{<InstagramOutlined />}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="#">
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
                  </svg>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="#">{<GithubOutlined />}</Link>
              </Menu.Item>
            </Menu>
            <p className="copyright">
              {" "}
              Copyright © 2021 Muse by <a href="#pablo">Creative Tim</a>.{" "}
            </p>
          </Footer> */}
      </Layout >
    </>
  )
}

export default SignIn
