import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Modal
} from "antd";
import signinbg from "../assets/images/flomaticLogo.png";
// import { useNavigate } from 'react-router-dom';

import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const ForgotPassword = () => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const [otpModal, setOtpModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [otpMail, setEmail] = useState();
  const [form] = Form.useForm();
  // const history = useNavigate();

  // const handleCheckboxChange = (e) => {
  //   setRemember(e.target.checked);
  // };
  const onFinish = (values) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    const { email } = values;
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    setLoading(true);
    let payload = {};
    payload.email = email;
    setEmail(email)
    request({
      url: "/admin/auth/forgot-password",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setVisible(false);
        setOtpModal(true);
        // setSelected({ email });
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleVerifyOTP = (values) => {
    const { otp } = values
    // Verify the OTP entered by the user
    let payload = {};
    payload.email = otpMail
    payload.otp = otp
    request({
      url: '/admin/auth/verify-otp',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setOtpModal(false);
        // setSelected(null);
        setVisible(false);
        setResetModal(true);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleReset = (values) => {
    const { newPassword } = values
    // Reset the password with the new password entered by the user
    let payload = {};
    payload.email = otpMail
    payload.password = newPassword
    request({
      url: '/admin/auth/reset-password',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setResetModal(false);
        navigate('/login')

      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleCancelReset = () => {
    setResetModal(false);
    navigate('/login')
  };

  useEffect(() => {
    const payload = JSON.parse(localStorage.getItem("remember_me"));
    if (!payload) return;
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {loading && <Loader />}
      <Layout
        className="layout-default layout-signin"
        style={{ backgroundColor: "white" }}

      >
        <Content className="signin">

          <div className="signin-box">
            <div className="signup-logo">
              <img src={signinbg} alt="" />
            </div>
            <Row justify="space-around">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
              >
                <div className="signup-form">
                  <Title className="mb-15">Forgot Password</Title>
                  <Title className="font-regular text-muted" level={5}>
                    Enter your email Id
                  </Title>
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>

                    {/* <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter password!",
                      },
                    ]}
                  >
                    <Input placeholder="Password" />
                  </Form.Item> */}
                    {/* <div className="form-group remember"> */}
                    {/* <Form.Item
                    className="username"
                    label=" Remember Me"
                    name="remember"
                  >
                          <Input
                            id="remember"
                            type="checkbox"
                            name="remember"
                            checked={remember}
                            onChange={handleCheckboxChange}
                          />
                         </Form.Item> */}
                    {/* </div> */}
                    {/* <Form.Item
                      name="remember"
                      className="aligin-center"
                      valuePropName="checked"
                    >
                      <Switch defaultChecked onChange={onChange} />
                      Remember me
                    </Form.Item> */}

                    {/* <Form.Item>
                    <Link
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      to="/send-otp"
                    >
                      Send Otp
                    </Link>
                  </Form.Item> */}
                    <div className="fw-cls">
                      <Form.Item>
                        <Button
                          type="default"
                          htmlType="submit"
                          style={{ width: "100%" }}
                          onClick={() => window.history.back()}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ width: "100%" }}
                        >
                          Send Otp
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Content>

        <Modal
          visible={otpModal}
          title="Verify OTP"
          okText="Ok"
          onCancel={() => setOtpModal(false)}
          okButtonProps={{
            form: 'verify-otp',
            htmlType: 'submit',
            loading: loading,
          }}
        >
          {/* <Modal
        visible={otpModal}
        title="Verify OTP"
        onCancel={() => setOtpModal(false)}
        footer={[
          <Button key="back" onClick={() => setOtpModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleVerifyOTP(selected.email, selected.otp)}(
          >
            Verify
          </Button>,
        ]}
      > */}
          <Form id="verify-otp" form={form} onFinish={(e) => handleVerifyOTP(e)} layout="vertical">
            <Form.Item
              label="OTP"
              name="otp"
              rules={[
                {
                  required: true,
                  message: 'Please enter the OTP',
                },
                {
                  len: 4,
                  message: 'OTP must be 4  digits',
                },
              ]}
            >
              <Input type="number" maxLength={4} placeholder="Enter OTP" />
            </Form.Item>
            {/* <Form.Item
          label="OTP"
          name="otp"
          rules={[
            {
              required: true,
              message: "Please input the OTP!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

          </Form>
        </Modal>

        <Modal
          visible={resetModal}
          title="Reset Password"
          okText="Ok"
          onCancel={handleCancelReset}
          okButtonProps={{
            form: 'reset-password',
            htmlType: 'submit',
            loading: loading,
          }}
        >
          {/* <Modal
        visible={resetModal}
        title="Reset Password"
        onCancel={handleCancelReset}
        footer={[
          <Button key="back" onClick={handleCancelReset}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleReset(selected.email, selected.otp, selected.newPassword)}
          >
            Reset
          </Button>,
        ]}
      > */}
          <Form id="reset-password" form={form} onFinish={(e) => handleReset(e)} layout="vertical">
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirm_new_password"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                { required: true, message: "Please input the confirm password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </Layout>
    </>
  );
};

export default ForgotPassword;
