import {
  Row,
  Col,
  Card,
  Table,
   Button,
  Typography,
  Tooltip,
  Modal,
  Form,
  Input,
} from "antd";
import JoditEditor from 'jodit-react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the desired Quill stylesheet
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import Main from "../components/layout/Main";

function Content() {

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  
  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Registartion date",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          new Date(created_at).toLocaleDateString()
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <Tooltip placement="top" title={"Edit"}>
            <Button className="tableBtn" onClick={() => {
              setSelected(record)
              setVisible(true)
            }}>
            <i class="fa fa-pencil" aria-hidden="true"></i>
            </Button>
          </Tooltip>
        );
      },
    },
  ];
  useEffect(() => {
    setLoading(true)
    request({
      url: '/admin/content/list',
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        // ShowToast(data.message, Severty.SUCCESS)
        setList(data.data.list)
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }, [refresh])

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24]}>
          <Col xs={24} xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Content "
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

    </Main>
  );
}
const AddFrom = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  
  const editor = useRef(null);

  const [loading, setLoading] = useState(false)
  const onCreate = (values) => {
    const { name, description } = values
    const payload = {}
    setLoading(true)
    payload.name = name
    payload.description = description
    
    request({
      url: `${data ? `/admin/content/edit-content/${data._id}` : ``}`,
      method: `${data ? 'PUT' : 'POST'}`,
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };
  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data }) }, [data])
  return (
    <Modal
      visible={show}
      title={`Edit  ${data.name} Details `}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish ={onCreate} layout="vertical">
        <Form.Item
          label="Title"
          name="name"
          rules={[
            { required: true, message: "Please input title!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
        <JoditEditor
			ref={editor}
			tabIndex={1}
      style = {{height: "30vh"}}
		/>
            {/* <ReactQuill type="textarea" style = {{height: "30vh"}} /> */}
          </Form.Item>
      </Form>
    </Modal>
  )
}

export default Content;
