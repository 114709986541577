import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Avatar,
  Button,
  Modal,
  Table,
  Image,
} from "antd";
import Main from "../components/layout/Main";
import useRequest from "../hooks/useRequest";
import { useParams } from "react-router";
import noUser  from "../assets/images/female.png";
import moment from "moment";


function ViewPhotographer() {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const [booking, setBooking] = useState([]);
  const [project, setProject] = useState([]);
  const [team, setTeam] = useState([]);
  const [visible, setVisible] = useState(false);
  const [photographer, setphotographer] = useState([]);
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const params = useParams();

  useEffect(() => {
    request({
      url: `/admin/photographer/photographer-details/${params.id}`,
      method: "GET",
      onSuccess: (data) => {
        // console.log(data?.data?.upcomingEvents);
        setEvent(data?.data?.upcomingEvents);
        setTeam(data?.data?.Teams);
        setProject(data?.data?.Projects);
        setBooking(data?.data?.Bookings);
        setphotographer(data?.data?.Photographer);
        // setSelected(data.data)
      },
    });
  }, [refresh]);

  const columns = [
    {
      title: "Client Name",
      render: (_, {client_id }) => {
        return (
          <div>
            {client_id.first_name  } {client_id.last_name  }
          </div>


        );
      },
      key: "name"
    },
    {
      title: "Email",
      render: (_, {client_id }) => {
        return (
          <div>
            {client_id.email  }
          </div>


        );
      },
      key: "email",
    },
    {
      title: "Address",
      render: (_, {client_id }) => {
        return (
          <div>
            {client_id.address  }
          </div>


        );
      },
      key: "address",
    },
    {
      title: "Mobile Number",
      render: (_, {client_id }) => {
        return (
          <div>
            {client_id.mobileNumber  }
          </div>


        );
      },
      key: "mobileNumber",
    },
    {
      title: "City",
      render: (_, {client_id }) => {
        return (
          <div>
            {client_id.city  }
          </div>


        );
      },
      key: "city",
    },
    {
      title: "State",
      render: (_, {client_id }) => {
        return (
          <div>
            {client_id.state  }
          </div>


        );
      },    
      key: "state",
    },
    {
      title: "Total Charges",
      render: (_, {client_id }) => {
        return (
          <div>
            {client_id.totalCharges  }
          </div>


        );
      },
      key: "totalCharges",
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
          <Button onClick={() => {
            setSelected(record)
            setVisible(true)
          }}>
            View
          </Button>
          </>
        );
      },
    }
  ];


  const column = [
    {
      title: "Member Name",
      render: (_, { firstName, lastName }) => {
        return (
          <div>
            {firstName + " " + lastName}
          </div>


        );
      },
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Hourly Fees",
      dataIndex: "hourly_rate",
      key: "hourly_rate",
    },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    //   key: "role",
    // }
  ];

  const eventColumn = [
    {
      title: "Event Type",
      render: (_, { booking }) => {
        return (
          <div>
            {booking.event_type[0].category_name}
          </div>


        );
      },
      key: "event_type"
    },
    {
      title: "Session Name",
      dataIndex: "session_name",
      key: "session_name",
    },
    {
      title: "Event Date",
      dataIndex: "date",
      render: (_, { date }) => {
        return (
          <div>
            {  moment(date).format("YYYY-MM-DD")}
          </div>


        );
      },
      key: "date",
    },
    {
      title: "Event Start Time",
      dataIndex: "startTime",
      key: "startTime",
    },
    {
      title: "Event end Time",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Event Venue",
      dataIndex: "venue",
      key: "venue",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    }
  ];


  return (
    <Main>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <>
            <h6 className="font-semibold">Photographer Details</h6>
          </>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={24} md={12} xl={6}>
            <Card bordered={false} className="card-project" style={{}}>
              <div className="card-tag">
              <Avatar.Group>
                <Image
                  width={200}
                  src={photographer.companyLogo ? photographer.companyLogo: noUser}
                />
                </Avatar.Group>
                <p>
                  Name : {photographer?.firstName + " " + photographer?.lastName}
                </p>
                <p> Email : {photographer?.email}</p>
                <p> Mobile Number: +{photographer?.countryCode} {photographer?.mobileNo}</p>
                <p>Company Name: {photographer?.companyName}</p>
              </div>
            </Card>
          </Col>
        </Row>
        <div className="tabled">
        <Row>
          <Col xs="24" xl={24}>
          <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Upcoming Events"
            >

              <div className="table-responsive">

                <Table
                  loading={loading}
                  columns={eventColumn}
                  dataSource={event}
                  className="ant-border-space"
                />
              </div>
            </Card>


          </Col>
        </Row>
      </div>
       
      <div className="tabled">
        <Row>
          <Col xs="24" xl={24}>
          <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Team Members"
            >

              <div className="table-responsive">

                <Table
                  loading={loading}
                  columns={column}
                  dataSource={team}
                  className="ant-border-space"
                />
              </div>
            </Card>


          </Col>
        </Row>
      </div>
      {/* <div>
      <div className="tabled">
        <Row>
          <Col xs="24" xl={24}>
          <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Bookings"
            >

              <div className="table-responsive">

                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={booking}
                  className="ant-border-space"
                />
              </div>
            </Card>


          </Col>
        </Row>
      </div>
      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      </div> */}
      {/* <div className="tabled">
        <Row>
          <Col xs="24" xl={24}>
          <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Projects"
            >

              <div className="table-responsive">

                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={project}
                  className="ant-border-space"
                />
              </div>
            </Card>


          </Col>
        </Row>
      </div> */}
      </Card>
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {

  // console.log(data, 438)
  return (
    <Modal
      open={show}
      title={` Details `}
      okText="Ok"
      onCancel={hide}
      
    >
  <Card>
  <Row gutter={[24, 24]}>
    <Col span={24} md={12} xl={6}>
          {console.log(data, 452)}
          <div>
                <h4 className="font-semibold m-0">Client Details</h4>
                <p className="text-dark">Client Name: {data?.client_id?.first_name+ " "+ data?.client_id?.first_name}</p>
                <p className="text-dark">Email: {data?.client_id?.email}</p>
                <p className="text-dark">Mobile Number: {data?.client_id?.mobileNumber}</p>
                <p className="text-dark"> Address: {data?.client_id?.address}</p>
                <p className="text-dark"> City: {data?.client_id?.city}</p>
                <p className="text-dark"> State: {data?.client_id?.state}</p>
                <p className="text-dark"> Country: {data?.client_id?.country}</p>
              </div>
              <div>
                <h4 className="font-semibold m-0">Additional Details</h4>
                <p className="text-dark">Package Name: {data?.package_id?.package_name}</p>
                <p className="text-dark">Package Type: {data?.package_id?.package_type}</p>
                <p className="text-dark">Package Price: {data?.package_id?.package_price}</p>
                <p className="text-dark"> Additional Hours: {data?.additionalHours}</p>
                <p className="text-dark"> Additional Fees: {data?.additionalFees}</p>
                <p className="text-dark"> Total Charges: {data?.totalCharges}</p>
                <p className="text-dark"> Due Date: {data?.due_date.split("T")[0]}</p>
                { data?.checkList?.map((list) =>(
                <p className="text-dark"> CheckList: {list?.name}</p>
                ))}
                { data?.item_id?.map((item) =>(
                <>
                 <p className="text-dark"> Additional Item: {item?.item_name}</p>
                 <p className="text-dark">  Item Price: {item?.item_price}</p>
                 </>
               ))}
              </div>
    </Col>
  </Row>
  </Card>
  </Modal>
)
}

export default ViewPhotographer;
