import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Avatar,
  Modal,
  Form,
  Upload,
  Input,
  Image,
  message
} from "antd";
import PhoneInput from 'react-phone-input-2'
import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import Main from "../components/layout/Main";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";
import { useRef } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';

const S3_BUCKET = 'inventcolabs';
const REGION = 'ap-south-1';
const ACCESS_KEY = 'AKIAXTJNUUWFIPVPT4U7';
const SECRET_ACCESS_KEY = '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';

const config = { 
  dirName: "flomatic/profile",
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}
function Profile() {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [refresh, setRefresh] = useState(false)
  const [editprofile, seteditProfile] = useState(false);
  const { logout } = useContext(AuthContext)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [form] = Form.useForm();
  const { request } = useRequest()
  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const onCreate = (values) => {
    const { old_password, new_password } = values
    const payload = {}
    if (!old_password || !new_password) return ShowToast('Please enter password ', Severty.ERROR)
    setLoading(true)
    payload.new_password = new_password
    payload.old_password = old_password
    request({
      url: '/admin/auth/change-password',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          setVisible(false)
          logout()
          // hide()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        // console.log(error.response.data, "Error")
        setLoading(false)
      },
    })
  };
  const changeProfile = () => {
    seteditProfile(true)
    setSelected(profile)
  }
  useEffect(() => {
    request({
      url: '/admin/auth/get-profile',
      method: 'GET',
      onSuccess: (data) => {
        console.log(data)
        // ShowToast(data.message, Severty.SUCCESS)
        setProfile(data.data)

      },
    })
  }, [refresh])
  return (
    <Main>
      {
        loading && <Loader />
      }
     <Row>
      <Col span={24} md={8}>
        <Card
          className="card-profile-head"
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle">
              <Col span={24} md={24} className="col-info">
                <div className="avatar-info-main d-flex jusify-content-between">
                    <Avatar.Group>
                      <Avatar size={74} shape="square" src={profile.image} />

                      <div className="avatar-info">
                        <h4 className="font-semibold m-0">{profile ? profile.name : ""}</h4>
                        <p>{profile ? profile.email : ""}</p>
                      </div>
                    </Avatar.Group>
                        <div className="">
                            <Button className="tableBtn" onClick={(e) => changeProfile()} >    
                                  <i class="fa fa-pencil" aria-hidden="true"></i>
                            </Button>
                          </div>
                    </div>
              </Col>
              <Col span={24}>
                <div className="profile-nav">
                  <div className="profile-nav-inner">
                    <Row>
                      <Col span={24} md={12}>
                        <Button onClick={(e) => setVisible(true)} >Change Password</Button>
                      </Col>
                      <Col span={24} md={12}>
                        <Button onClick={logout} >Logout</Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          }
        ></Card>
      </Col>
     </Row>

      <Modal
        visible={visible}
        title="Change password"
        className="forgotPassModal"
        okText="Ok"
        onCancel={() => {
          form.resetFields(); 
          setVisible(false);
        }}
        onOk={handleCreate}
      >
        <Form form={form} layout="vertical" className="change-pw">
          <Form.Item
            label="Old Password"
            name="old_password"
            hasFeedback
            rules={[
              { required: true, message: "Please input the old password!" }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            hasFeedback
            rules={[
              { required: true, message: "Please input the new password!" }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_new_password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              { required: true, message: "Please input the confirm password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords does not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>

      {editprofile && <AddFrom show={editprofile} hide={() => { setSelected(); seteditProfile(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>

  );
}


const AddFrom = ({ show, hide, data, refresh }) => {

  const [form] = Form.useForm();
  const { request } = useRequest()

  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
        message.error("File format is not correct")
        return false
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        message.error(`Image must be smaller than 5 MB!`)
        return false
    }
    return true
  }

  const handleRemove = async() => {
    setFile([]);
     setImage([data.image])
  }


  const handleChange = async (event) => {
    const { file } = event
    setFile([file])
    uploadFile(file, config)
      .then(data => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: data.location,
          thumbUrl: data.location,
        }
        setFile([fileData])
        setImage( data.location)
        console.log(data)
      }
      )
      .catch(err => console.error(err))
  };


 
  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
    data.image&& setImage([data.image])
  }, [data])

  const onCreate = (values) => {
    const {  email, name } = values
    const payload = {}
    setLoading(true)
    payload.email = email
    payload.name = name
    payload.image = file.length > 0 ? file[0].url : null
    
    request({
      url: `/admin/auth/edit-profile`,
      method: 'PUT',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.data.error[0], Severty.ERROR)
        // console.log(error.response.data, "Error")
        setLoading(false)
      },
      // onErrorSubmit: (data) => {
      //   console.log(data)
      // }


    })
    // console.log(values)
  };
 
  return (
    <Modal
    className="editproModal"
      visible={show}
      title={`Edit Profile `}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish ={onCreate} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input the email!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="User Name"
          name="name"
          rules={[
            { required: true, message: "Please input user name!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Upload Profile" name="image" rules={[ { required: true, message: "Please enter the profile image!" } ]} >
              <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleChange}
                onRemove= {handleRemove}
                fileList={file}
              >
                {file && file.length > 0 ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
              
              </Upload>
                <div className="imgPreview"> <Image src={image ? image : ''}></Image> </div>

            </Form.Item>
       
      </Form>
    </Modal>
  )
}
export default Profile;
